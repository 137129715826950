import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="119px" height="22px" viewBox="0 0 119 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fontFamily="Eina01Regular" fontSize="28" fontWeight="normal" letterSpacing="-1.21333333">
          <g transform="translate(-65.000000, -65.000000)">
            <text id="Mara-+-Co">
              <tspan x="63.0526667" y="86" fill={color}>Mara</tspan>
              <tspan x="123.747333" y="86" letterSpacing="-3.08" fill={color} />
              <tspan x="127.947333" y="86" fill="#C64867">+</tspan>
              <tspan x="143.03" y="86" letter-spacing="-3.08" fill={color} />
              <tspan x="147.23" y="86" fill={color}>Co</tspan>
            </text>
          </g>
        </g>
      </svg>
    )
  }
}

class YouTube extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="23px" height="16px" viewBox="0 0 23 16" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1355.000000, -67.000000)" fill={color}>
            <g id="/i/youtube" transform="translate(1355.000000, 67.000000)">
              <path d="M22.6349206,11.8191726 L22.6349206,3.84398525 C22.6349206,3.84398525 22.6349206,-2.61778903e-15 18.7342296,-2.61778903e-15 L3.89936015,-2.61778903e-15 C3.89936015,-2.61778903e-15 0,-2.61778903e-15 0,3.84398525 L0,11.8191726 C0,11.8191726 0,15.6631579 3.89936015,15.6631579 L18.7342296,15.6631579 C18.7342296,15.6631579 22.6349206,15.6631579 22.6349206,11.8191726 M15.7118928,7.84272664 L8.30177769,12.1313079 L8.30177769,3.55283386 L15.7118928,7.84272664"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Instagram extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1313.000000, -66.000000)" fill={color}>
            <g id="/i/instagram" transform="translate(1313.000000, 66.000000)">
              <path d="M5.27741655,-3.55271368e-15 L13.008104,-3.55271368e-15 C15.9111902,-3.55271368e-15 18.2851777,2.34461691 18.2851777,5.20890427 L18.2851777,12.918678 C18.2851777,15.7836511 15.9111902,18.1275823 13.008104,18.1275823 L5.27741655,18.1275823 C2.37433032,18.1275823 0,15.7836511 0,12.918678 L0,5.20890427 C0,2.34461691 2.37430175,-3.55271368e-15 5.27741655,-3.55271368e-15 Z M9.09333313,4.16956335 C11.9144218,4.16956335 14.2036974,6.45883902 14.2036974,9.27992766 C14.2036974,12.1013591 11.9144218,14.390292 9.09333313,14.390292 C6.2715588,14.390292 3.98262598,12.1013591 3.98262598,9.27992766 C3.98262598,6.45883902 6.2715588,4.16956335 9.09333313,4.16956335 Z M9.09333313,5.89562698 C10.9614783,5.89562698 12.4776338,7.41146821 12.4776338,9.27992766 C12.4776338,11.1483871 10.9614497,12.6645712 9.09333313,12.6645712 C7.22453083,12.6645712 5.70868961,11.1484157 5.70868961,9.27992766 C5.70868961,7.41146821 7.22453083,5.89562698 9.09333313,5.89562698 Z M14.0373309,3.46126985 C14.4956032,3.46126985 14.8671351,3.8328018 14.8671351,4.29073122 C14.8671351,4.74900349 14.4956032,5.12053544 14.0373309,5.12053544 C13.5794015,5.12053544 13.2078695,4.74900349 13.2078695,4.29073122 C13.2078695,3.8328018 13.5794015,3.46126985 14.0373309,3.46126985 L14.0373309,3.46126985 Z M5.9091123,1.48075654 L12.3770653,1.48075654 C14.8057083,1.48075654 16.7919643,3.45721283 16.7919643,5.87239909 L16.7919643,12.3727226 C16.7919643,14.7878517 14.8057369,16.7639937 12.3770653,16.7639937 L5.9091123,16.7639937 C3.48046929,16.7639937 1.49389901,14.7878517 1.49389901,12.372694 L1.49389901,5.87234195 C1.49389901,3.45721283 3.48046929,1.48075654 5.9091123,1.48075654 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class RightArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1363.000000, -162.000000)">
            <g transform="translate(1362.894118, 162.000000)">
              <g transform="translate(6.794118, 7.000000) scale(-1, 1) translate(-6.794118, -7.000000)">
                <g transform="translate(6.656155, 7.000000) rotate(90.000000) translate(-6.656155, -7.000000) translate(0.000000, 0.411765)">
                  <polygon fill={color} fillRule="nonzero" points="6.03849121 0.0185693147 7.27324876 0.0185693147 7.27324876 12.3677749 6.03849121 12.3677749"></polygon>
                  <polygon fill={color} fillRule="nonzero" points="0.980057732 5.62839054 7.52903529 12.1773681 6.65562547 13.0507779 0.106647908 6.50180036"></polygon>
                  <polygon fill={color} fillRule="nonzero" points="12.3322256 5.62839054 13.2056354 6.50180036 6.65665785 13.0507779 5.78324803 12.1773681"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Plus extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-429.000000, -1448.000000)" fill={color}>
            <g transform="translate(429.000000, 1448.000000)">
              <polygon points="15.2283871 27.3135484 15.2283871 14.9574194 27.8554839 14.9574194 27.8554839 12.4103226 15.2283871 12.4103226 15.2283871 0 12.6812903 0 12.6812903 12.4103226 5.13424428e-14 12.4103226 5.13424428e-14 14.9574194 12.6812903 14.9574194 12.6812903 27.3135484"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class DownArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1303.000000, -194.000000)">
            <g transform="translate(1301.982667, 191.882353)">
              <g transform="translate(36.882353, 38.000000) scale(1, -1) rotate(90.000000) translate(-36.882353, -38.000000)">
                <g transform="translate(36.133414, 38.000000) rotate(90.000000) translate(-36.133414, -38.000000) translate(0.000000, 2.235294)">
                  <polygon fill={color} fillRule="nonzero" points="32.7803808 0.100804851 39.4833504 0.100804851 39.4833504 67.1393494 32.7803808 67.1393494"></polygon>
                  <polygon fill={color} fillRule="nonzero" points="5.3203134 30.5541201 40.8719059 66.1057126 36.1305383 70.8470802 0.578945785 35.2954877"></polygon>
                  <polygon fill={color} fillRule="nonzero" points="66.9463675 30.5541201 71.6877351 35.2954877 36.1361426 70.8470802 31.394775 66.1057126"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}


class Facebook extends Component {

  render() {

    const { color } = this.props

    return (
            
      <svg width="9px" height="19px" viewBox="0 0 9 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-840.000000, -375.000000)" fill={color}>
            <g transform="translate(840.000000, 375.000000)">
              <path d="M5.86793103,5.43016393 L5.86793103,3.96819672 C5.86793103,3.28196721 6.33862069,3.10295082 6.71517241,3.10295082 L8.84896552,3.10295082 L8.84896552,-5.32907052e-15 L5.89931034,-5.32907052e-15 C2.63586207,-5.32907052e-15 1.91413793,2.29737705 1.91413793,3.78918033 L1.91413793,5.43016393 L3.8191672e-14,5.43016393 L3.8191672e-14,7.60819672 L3.8191672e-14,9.04032787 L1.91413793,9.04032787 L1.91413793,18.0806557 L5.71103448,18.0806557 L5.71103448,9.04032787 L8.53517241,9.04032787 L8.66068966,7.60819672 L8.88034483,5.40032787 L5.86793103,5.40032787 L5.86793103,5.43016393 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </svg>

    )
  }

}

class Close extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1360.000000, -67.000000)" fill={color}>
            <g transform="translate(1357.500000, 64.970563)">
              <g transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) ">
                <polygon points="13.0529032 23.4116129 13.0529032 12.8206452 23.876129 12.8206452 23.876129 10.6374194 13.0529032 10.6374194 13.0529032 0 10.8696774 0 10.8696774 10.6374194 4.40078082e-14 10.6374194 4.40078082e-14 12.8206452 10.8696774 12.8206452 10.8696774 23.4116129"></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Phone extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color} fillRule="nonzero">
            <path d="M0.32686762,6.15611621 C0.32686762,6.15611621 -0.206548317,4.47547589 0.089454016,2.85566492 C0.32686762,1.29524448 1.15628589,0.695064327 1.39294962,0.515413945 C1.63036323,0.395202061 1.98572389,0.155553647 1.98572389,0.155553647 C1.98572389,0.155553647 2.4004429,-0.264416589 2.8745005,0.275765531 C3.3485581,0.755825724 5.54088951,3.03586684 5.54088951,3.03586684 C5.54088951,3.03586684 5.95560852,3.51592703 5.6002439,3.99600721 C5.24488323,4.41597744 3.99997636,5.67664752 3.99997636,5.67664752 C4.53339229,7.71714813 8.14795571,11.3772999 10.1627449,11.91745 C10.1627449,11.91745 11.4068822,10.7168899 11.8809398,10.3570296 C12.2956588,9.99716927 12.7697164,10.4171355 12.7697164,10.4171355 C12.7697164,10.4171355 15.0213075,12.5777361 15.5547037,13.0579362 C16.0287613,13.5981063 15.6140581,13.9579666 15.6140581,13.9579666 C15.6140581,13.9579666 15.4359949,14.3779368 15.2586974,14.5582467 C15.1399886,14.7986664 14.4886375,15.6385869 12.9477085,15.9383373 C11.4067993,16.1787571 9.68852551,15.6385869 9.68852551,15.6385869 C6.13255082,14.1983864 1.74737492,9.81763479 0.325182381,6.15668373 L0.32686762,6.15611621 Z"></path>
          </g>
        </g>
      </svg>
    )
  }
}

export { Logo, YouTube, Instagram, RightArrow, Plus, DownArrow, Close, Facebook, Phone }
