import React, { Component } from 'react'
import Link from '../utils/link'

import { YouTube, Instagram, Facebook, Phone } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--open'

    return (
      <>
        <header className={headerClass}>
          <div className='header__announcement'>
            <Link to='/appraisals/'>What is my property worth?</Link>
          </div>
          <div className='header__inner'>
            <Link to='/' title='Mara+Co' className='header__logo' {...props}>
              <div className='header__logo-text'>Mara<span>+</span>Co</div>
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/sold/' {...props}>Sold</Link></li>
                <li><Link to='/buy/' {...props}>Buy</Link></li>
                <li><Link to='/lease/' {...props}>Lease</Link></li>
                <li><Link to='/about-us/' {...props}>Us</Link></li>
                <li><Link to='/concierge/' {...props}>Concierge</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li className='phone-number'><Link to='tel:+61283223086'><Phone color={'#C64867'} /> 02 8322 3086</Link></li>
                <li><Link to='https://www.facebook.com/maraandcorealestate/'><Facebook color={'#C64867'} /></Link></li>
                <li><Link to='https://www.instagram.com/maraandcoedgecliff/'><Instagram color={'#C64867'} /></Link></li>
                <li><Link to='https://www.youtube.com/channel/UClQf05LaYGcyDAvFqriZTqg'><YouTube color={'#C64867'} /></Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/sold/' {...props}>Sold</Link></li>
                <li><Link to='/buy/' {...props}>Buy</Link></li>
                <li><Link to='/lease/' {...props}>Lease</Link></li>
                <li><Link to='/about-us/' {...props}>Us</Link></li>
                <li><Link to='/concierge/' {...props}>Concierge</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li className='phone-number'><Link to='tel:+61283223086'><Phone color={'#C64867'} /> 02 8322 3086</Link></li>
                <li className='social'><Link to='https://www.facebook.com/maraandcorealestate/'><Facebook color={'#C64867'} /></Link><Link to='https://www.instagram.com/maraandcoedgecliff/'><Instagram color={'#C64867'} /></Link><Link to='https://www.youtube.com/channel/UClQf05LaYGcyDAvFqriZTqg'><YouTube color={'#C64867'} /></Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
