import React, { Component } from 'react'
import Link from '../utils/link'

import { YouTube, Instagram, Facebook } from './icons'
import Newsletter from './newsletter'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__logo'>
            <Link to='/' title='Mara+Co' className='footer__logo-text'>Mara<span>+</span>Co</Link>
          </div>
          <div className='footer__cols'>
            <div className='footer__col'>
              <ul>
                <li><Link to='/sold/'>Sold</Link></li>
                <li><Link to='/buy/'>Buy</Link></li>
                <li><Link to='/lease/'>Lease</Link></li>
                <li><Link to='/about-us/'>Us</Link></li>
                <li><Link to='/concierge/'>Concierge</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <ul>
                <li>
                  <span>Call</span>
                  <Link to='tel:+61283223086'>02 8322 3086</Link>
                </li>
                <li>
                  <span>Email</span>
                  <Link to='mailto:sales@maraandco.com.au'>sales@maraandco.com.au</Link>
                </li>
                <li>
                  <span>Edgecliff</span>
                  <Link to='/contact/'>135-153 New South Head Rd<br />Edgecliff NSW 2027</Link>
                </li>
                <li>
                  <span>Socials</span>
                  <ul className='footer__social'>
                    <li><Link to='https://www.facebook.com/maraandcorealestate/'><Facebook color={'#C64867'} /></Link></li>
                    <li><Link to='https://www.instagram.com/maraandcoedgecliff/'><Instagram color={'#C64867'} /></Link></li>
                    <li><Link to='https://www.youtube.com/channel/UClQf05LaYGcyDAvFqriZTqg'><YouTube color={'#C64867'} /></Link></li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className='footer__col'>
              <span>Subscribe to our newsletter</span>
              <Newsletter />
            </div>
            <div className='footer__col'>
              <p>All rights reserved { new Date().getFullYear() }.<br />Design by <Link to='https://atollon.com.au'>Atollon</Link></p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
